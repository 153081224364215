<template>
  <div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" />
		<div role="alert" id="textToast" style="display: none;">
			<div class="weui-mask_transparent"></div>
			<div class="weui-toast weui-toast_text">
				<p class="weui-toast__content"></p>
			</div>
		</div>
  </div>
</template>

<script>
export default {
	name: 'App',
	created() {
		console.log('app created')
		let that = this;
		setTimeout(function () {
			console.log('激活')
			that.$util.pageStopover(90);
		}, 90000);
	},
	beforeDestroy() {

	},
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.video-js {
	width: 100%;
	height: 100%;
}
.vjs-big-play-button {
	position: absolute!important;
	top: 50%!important;
	left: 50%!important;
	transform: translate(-50%, -50%);
}
.video-js .vjs-big-play-button {
	width: 50px!important;
	height: 50px!important;
	border-radius: 50%!important;
	border: none!important;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)!important;
	font-size: 24px!important;
	line-height: 50px!important;
	color: #fff;
}
.video-js .vjs-volume-panel {
	display: none !important;
}
</style>