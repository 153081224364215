<template>
	<div class="top" style="background-size: 100% 4rem;">
		<div class="recently">
			<div class="recently-head">
				<div>最近观看</div>
			</div>
			<div class="recently-body">
				<div class="teleplay-list" v-if="viewing.length > 0">
					<div class="teleplay" v-for="item in viewing" :key="item.id" @click="toDetail" :data-id="item.id">
						<div class="img-container"><img :src="item.cover" alt=""></div>
						<div class="title">{{item.name}}</div>
					</div>
				</div>
				<div class="empty" v-else>
					<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogram/theater/index/empty4.png" />
				</div>
			</div>
		</div>

		<div class="my">
			<div class="my-head">
				<div>我的追剧</div>
			</div>
			<div class="my-body">
				<template v-if="collect.length > 0">
					<div class="my-theater" v-for="(item, index) in collect" :key="index" :data-id="item.id" @click.stop="toDetail" v-if="index < 2">
						<div class="theater-left">
							<div class="img-container"><img :src="item.cover" /></div>
						</div>
						<div class="theater-right">
							<div class="theater-title">{{item.name}}</div>
							<div class="theater-schedule">
								<div>看到第{{item.chasingFor}}集</div>
								<div class="button" :data-id="item.id" @click.stop="toCancel">取消</div>
							</div>
							<div class="theater-status">
								<div class="theater-number">共{{item.updatedEpisodes}}集</div>
							</div>
						</div>
					</div>
				</template>
				<div class="empty" v-else>
					<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogram/theater/index/empty4.png" mode="heightFix">
				</div>
			</div>
		</div>

		<div class="like">
			<div class="like-head">
				<div>猜你喜欢</div>
				<div class="more" @click="refresh">
					<div>换一换</div>
					<img :class="{'rotating-element':isRefresh}" src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/index/refresh.png" />
				</div>
			</div>
			<div class="like-body">
				<template v-if="recommended.length > 0">
					<div class="like-theater" v-for="(item, index) in recommended" :key="index" :data-id="item.id" @click="toDetail" v-if="index < 6">
						<div class="img-container"><img mode="aspectFill" :src="item.cover"></div>
						<div class="theater-title">{{item.name}}</div>
						<div class="tag-list">
							<div class="tag" v-for="(item, index) in item.tags" :key="index">{{item}}</div>
						</div>
					</div>
				</template>
				<div class="empty" v-else>
					<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogram/theater/index/empty4.png" mode="heightFix">
				</div>
			</div>
		</div>

		<div class="get" @click="toAward" v-if="receiveImgShow">
			<img :src="receiveImgUrl">
		</div>
	</div>
</template>

<script>
export default {
	name: 'MyTheatre',
	data() {
		return {
			list: [],
			viewing: [],
			collect: [],
			recommended: [],
			recommendedCurrentIndex: 0,
			isRefresh: false,
			original: [],
			animationData: {},
			imageHide: false,
			scrollLeft: '',
			receiveImgShow: false,
			receiveImgUrl: '',
			enterTime: 0,
			path: '',
		}
	},
	activated() {
		this.$util.setTitle('追剧');
		this.path = '/my/theatre' + window.location.search;
		this.enterTime = new Date().getTime();
	},
	deactivated() {
		const leaveTime = new Date().getTime();
		const stayTime = leaveTime - this.enterTime;
		this.$util.pageStopover(this.path, Math.floor(stayTime / 1000));
	},
	beforeDestroy() {
		const leaveTime = new Date().getTime();
		const stayTime = leaveTime - this.enterTime;
		this.$util.pageStopover(this.path, Math.floor(stayTime / 1000));
	},
	mounted() {
		let that = this;
		that.$util.showLoading()
		that.$util.axios.get('api/applet/v1/user/viewing/record', {}).then(function (res) {
			if (res.code == 100) {
				that.viewing = res.data.list;
				that.scrollLeft = Math.random() * 0.1;
			}
		}).catch(function (error) {
			console.log('viewing获取失败', error)
		});
		that.$util.axios.get('api/applet/v1/user/collect/list', {}).then(function (res) {
			if (res.code == 100) {
				that.collect = res.data.list;
			}
		}).catch(function (error) {
			console.log('collect获取失败', error)
		});
		that.$util.axios.get('api/applet/v1/playlet/recommended?needRandom=1&limit=18', {}).then(function (res) {
			if (res.code == 100) {
				that.original = res.data.list;
				that.refresh(true);
			}
		}).catch(function (error) {
			console.log('recommended获取失败', error)
		});

		that.$util.axios.get('api/applet/v1/playlet/config', {}).then(function (res) {
			if (res.code == 100) {
				that.receiveImgShow = res.data.receiveImgShow;
				that.receiveImgUrl = res.data.receiveImgUrl;
			}
		}).catch(function (error) {
			console.log('config获取失败', error)
		});
		that.$util.setTitle('追剧');
		that.$util.wechatConfig();
		that.path = '/my/theatre' + window.location.search;
		that.enterTime = new Date().getTime();
		that.$util.accessLog(that.path, {});
	},
	methods: {
		refresh(e) {
			let that = this;
			if (e !== true) {
				that.isRefresh = true;
				setTimeout(function () {
					that.isRefresh = false;
				}, 1000);
			}
			this.recommended = this.getRandomElements(this.original, 6);
		},
		toDetail(e) {
			console.log(e)
			console.log(e.currentTarget.dataset.id)
			this.$router.push({
				path: '/detail',
				query: {
					plet_id: e.currentTarget.dataset.id
				}
			})
		},
		toCancel(e) {
			let that = this;
			let id = e.currentTarget.dataset.id;
			that.$weui.confirm('', function(){
				that.$util.axios.post('api/applet/v1/user/collect/del', {
					playletId: id,
				}).then(function (res) {
					console.log('取消追剧成功', res)
					that.$util.axios.get('api/applet/v1/user/collect/list').then(function (res) {
						console.log('collect获取成功', res)
						that.collect = res.data.list;
					}).catch(function (error) {
						console.log('collect获取失败', error)
					});
				}).catch(function (error) {
					console.log('取消追剧失败', error)
				});
			}, function(){}, {
				title: '确定取消追剧吗？'
			});
		},
		toList(e) {
			this.$router.push({
				path: '/list',
				query: {
					t: e.currentTarget.dataset.type
				}
			})
		},
		toAward() {
			this.$router.push({
				path: '/wechat'
			})
		},
		getRandomElements: function (arr, num) {
			const result = [];
			const length = arr.length;

			if (length <= num) {
				return this.shuffleArray(arr);
			}

			while (result.length < num) {
				const randomIndex = Math.floor(Math.random() * length);
				const randomElement = arr[randomIndex];
				if (!result.includes(randomElement)) {
					result.push(randomElement);
				}
			}

			return result;
		},
		shuffleArray: function (array) {
			const shuffledArray = array.slice();
			for (let i = shuffledArray.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
			}
			return shuffledArray;
		}
	}
}
</script>

<style scoped>
.top {
	margin: 0 auto;
	padding-top: 0.36rem;
	padding-bottom: 1.2rem;
	background: #F7F7F7;
}
.recently {
	width: 7.02rem;
	background: #FFFFFF;
	border-radius: 0.24rem;
	margin: 0 auto 0.20rem;
	padding: 0.32rem 0 0.10rem 0.22rem;
	box-sizing: border-box;
}
.recently .recently-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 0.32rem;
	font-weight: 600;
	font-size: 0.32rem;
	color: #333333;
}
.recently .recently-body {
	margin-top: 0.34rem;
	display: flex;
	justify-content: flex-start;
	white-space: nowrap;
}
.recently .teleplay-list {
	display: flex;
	justify-content: flex-start;
	white-space: nowrap;
	height: 3.24rem;
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.recently .teleplay-list::-webkit-scrollbar {
	display: none !important;
	width: 0 !important;
	height: 0 !important;
	-webkit-appearance: none !important;
	background: transparent !important;
	color:transparent !important;
}
.recently .teleplay-list .teleplay {
	width: 2.02rem;
	margin-right: 0.18rem;
	display: inline-block;
}
.teleplay-list .teleplay img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.recently .teleplay-list .teleplay .title {
	height: 0.36rem;
	width: 2.02rem;
	overflow: hidden;
	margin-top: 0.12rem;
	font-size: 0.26rem;
	color: #333333;
	font-weight: 400;
}
.img-container {
	width: 2.04rem;
	height: 2.62rem;
	border-radius: 0.10rem;
	overflow: hidden;
	position: relative;
}

.more {
	height: 0.36rem;
	font-weight: 400;
	font-size: 0.26rem;
	color: #222126;
	line-height: 0.36rem;
	display: flex;
	align-items: center;
}
.more div {
	display: inline-block;
}
.more img {
	width: 0.22rem;
	height: 0.22rem;
}

.my {
	width: 7.02rem;
	background: #FFFFFF;
	border-radius: 0.24rem;
	margin: 0 auto 0.22rem;
	padding: 0.32rem 0.24rem 0.24rem;
	box-sizing: border-box;
}
.my .my-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 0.32rem;
	font-weight: 600;
	font-size: 0.32rem;
	color: #333333;
}
.my-body {
	padding: 0.12rem 0 0;
}
.my .my-theater {
	height: 2.62rem;
	display: flex;
	align-items: center;
	margin-top: 0.22rem;
}
.my .my-theater:last-child {
	border: none;
}
.my .my-theater .theater-left {
	width: 2.02rem;
	margin-right: 0.26rem;
}
.my .my-theater .theater-left .img-container {
	width: 2.02rem;
	height: 2.60rem;
	border-radius: 0.10rem;
	overflow: hidden;
	position: relative;
}
.my .my-theater .theater-left img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.my .my-theater .theater-right {
	width: 4.3rem;
	overflow: hidden;
}
.my .my-theater .theater-right .theater-title {
	height: 0.44rem;
	font-weight: 600;
	font-size: 0.32rem;
	color: #333333;
	line-height: 0.44rem;
	text-align: left;
}
.my .my-theater .theater-right .theater-schedule {
	height: 0.52rem;
	font-weight: 400;
	font-size: 0.28rem;
	color: #999999;
	line-height: 0.52rem;
	margin-top: 0.14rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.my .my-theater .theater-right .theater-schedule .button {
	width: 1.12rem;
	height: 0.52rem;
	background: linear-gradient( 124deg, #56E9B1 0%, #56E8B0 100%);
	border-radius: 0.32rem;
	font-weight: 500;
	font-size: 0.26rem;
	color: #FFFFFF;
	text-align: center;
}
.my .my-theater .theater-right .theater-status {
	height: 0.28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.06rem;
}
.my .my-theater .theater-right .theater-number {
	height: 0.40rem;
	font-size: 0.28rem;
	font-weight: 400;
	color: #56E9B1;
	line-height: 0.40rem;
}

.like {
	width: 7.02rem;
	background: #FFFFFF;
	border-radius: 0.24rem;
	margin: 0 auto 0.22rem;
	padding: 0.32rem 0.16rem 0.24rem;
	box-sizing: border-box;
	margin-bottom: calc(0.32rem + env(safe-area-inset-bottom));
}
.like .like-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	font-size: 0.32rem;
	color: #333333;
	margin-left: 0.06rem;
}
.like .like-body {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	white-space: nowrap;
}
.like .like-theater {
	width: 1.96rem;
	overflow: hidden;
	margin-right: 0.40rem;
	margin-top: 0.18rem;
}
.like .like-body .like-theater:nth-child(3n) {
	margin-right: 0;
}
.like .like-theater .img-container {
	width: 1.96rem;
	height: 2.60rem;
	border-radius: 0.10rem;
}
.like .like-theater img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.like .like-theater .theater-title {
	width: 1.96rem;
	height: 0.30rem;
	overflow: hidden;
	font-size: 0.26rem;
	color: #333333;
	font-weight: 400;
	line-height: 0.30rem;
	display: flex;
	align-items: flex-end;
	margin-top: 0.08rem;
}
.like .like-theater .tag-list {
	display: flex;
	height: 0.46rem;
	overflow: hidden;
	box-sizing: border-box;
	flex-wrap: wrap;
}
.like .like-theater .tag-list .tag {
	height: 0.32rem;
	background: #F5F6F8;
	border-radius: 0.04rem;
	padding: 0 0.12rem;
	font-weight: 400;
	font-size: 0.22rem;
	color: #8F9091;
	margin-top: 0.14rem;
	margin-right: 0.08rem;
}

.get {
	position: fixed;
	width: 1.42rem;
	bottom: 0.94rem;
	right: 0.24rem;
}
.get img {
	display: block;
	width: 1.42rem;
	height: 1.74rem;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.rotating-element {
	animation: rotate 2s linear infinite;
}
</style>