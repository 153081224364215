import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "weui"
import 'weui/dist/style/weui.min.css'
import weui from 'weui.js'
Vue.prototype.$weui = weui

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
Vue.use(VueVideoPlayer)

Vue.prototype.$util = util

Vue.config.productionTip = false

Vue.config.performance = true;

Vue.prototype.$toast = function(message) {
  const toast = document.getElementById('textToast');
  const content = toast.querySelector('.weui-toast__content');
  content.textContent = message;
  toast.style.display = 'block';

  setTimeout(() => {
    toast.style.display = 'none';
    content.textContent = '';
  }, 3000);
};

import util from './libs/util'

import VueClipBoard from 'vue-clipboard2';
Vue.use(VueClipBoard);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
