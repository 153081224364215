<template>
  <div class="home">
    <div class="weui-tab" id="tab">
      <div id="panel1" role="tabpanel" aria-labelledby="tab1" class="weui-tab__panel">
	      <Index v-if="activeTab == 0" :changeTabFunc="changeTabFunc"></Index>
				<MyTheatre v-if="activeTab == 1"></MyTheatre>
				<Welfare v-if="activeTab == 2">福利</Welfare>
				<My v-if="activeTab == 3" :changeTabFunc="changeTabFunc">我的</My>
      </div>
      <div role="tablist" aria-label="选项卡标题" class="weui-tabbar">
	      <div id="tab1" role="tab" aria-labelledby="t2_title" aria-selected="false" aria-controls="panel2" class="weui-tabbar__item" :class="{ 'weui-bar__item_on': activeTab == 0 }">
		      <img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/tabBar/index-2.png" alt="" class="weui-tabbar__icon" v-if="activeTab == 0">
		      <img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/tabBar/index-1.png" alt="" class="weui-tabbar__icon" v-else>
		      <p aria-hidden="true" id="t2_title" class="weui-tabbar__label">首页</p>
	      </div>
        <div id="tab2" role="tab" aria-labelledby="t1_title" aria-describedby="t1_tips" aria-selected="true" aria-controls="panel1" class="weui-tabbar__item" :class="{ 'weui-bar__item_on': activeTab == 1 }">
          <img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/tabBar/playlet-2.png" alt="" class="weui-tabbar__icon" v-if="activeTab == 1">
          <img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/tabBar/playlet-1.png" alt="" class="weui-tabbar__icon" v-else>
          <p id="t1_title" aria-hidden="true" class="weui-tabbar__label">追剧</p>
        </div>
        <div id="tab3" role="tab" aria-labelledby="t3_title" aria-describedby="t3_tips" aria-selected="false" aria-controls="panel3" class="weui-tabbar__item" :class="{ 'weui-bar__item_on': activeTab == 2 }">
	        <img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/tabBar/welfare-2.png" alt="" class="weui-tabbar__icon" v-if="activeTab == 2">
	        <img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/tabBar/welfare-1.png" alt="" class="weui-tabbar__icon" v-else>
          <p id="t3_title" aria-hidden="true" class="weui-tabbar__label">福利</p>
        </div>
        <div id="tab4" role="tab" aria-labelledby="t4_title" aria-selected="false" aria-controls="panel4" class="weui-tabbar__item" :class="{ 'weui-bar__item_on': activeTab == 3 }">
          <img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/tabBar/my-2.png" alt="" class="weui-tabbar__icon" v-if="activeTab == 3">
          <img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/tabBar/my-1.png" alt="" class="weui-tabbar__icon" v-else>
          <p class="weui-tabbar__label" aria-hidden="true" id="t4_title">我的</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Index from '@/views/Index.vue'
import MyTheatre from '@/views/MyTheatre.vue'
import Welfare from '@/views/Welfare.vue'
import My from '@/views/My.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
		Index,
		MyTheatre,
		Welfare,
		My,
  },
  data() {
    return {
      activeTab: 10
    }
  },
	mounted() {
		let that = this;
		console.log('HomeView mounted')
		const options = that.$route.query;
		console.log('options', options);
		let defaultIndex = 0;
		if (options.tab) {
			defaultIndex = parseInt(options.tab);
		}
		that.activeTab = defaultIndex;
		that.$weui.tab('#tab',{
			defaultIndex: defaultIndex,
			onChange: function(index){
				console.log(index);
				that.activeTab = index;
			}
		});
	},
	methods: {
		changeTabFunc(index) {
			this.activeTab = index;
		}
	},
}
</script>
<style>
  .weui-bar__item_on .weui-tabbar__label {
    color: #56E9B1!important;
  }
  .weui-tabbar {
		position: fixed!important;
		width: 100%;
		bottom: 0;
		background: rgba(255,255,255,1)!important;
		z-index: 1000!important;
		padding-bottom: env(safe-area-inset-bottom);
  }
	.weui-tabbar__item {
		padding-bottom: 0 !important;
	}
</style>