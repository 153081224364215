import axios from 'axios'
import store from '../store'
import weui from 'weui.js'
import Vue from 'vue'
import CryptoJS from 'crypto-js'

let util = {}

util.baseUrl = 'https://wechat-applets.bjyddj.com/'
util.logBaseUrl = 'https://log-applets.bjyddj.com/'

util.setTitle = function (title) {
    window.document.title = title;
};

util.axios = axios.create({
    baseURL: util.baseUrl,
    headers:{},
    withCredentials: false
});
const res = {
    'resolve': function(response) {
        util.hideLoading()
        // if (response.data.code == 102) {
        //     return response.data
        // }
        if (response.data.str) {
            response.data = util.decrypt(response.data.str);
        }
        return response.data;
    },
    'reject': function(error) {
        util.hideLoading()
        //weui.alert('系统错误');
        Vue.prototype.$toast('系统错误')
        return Promise.reject(null);
    }
};
const requestConfig = function (res) {
    const fixedData = {
        type: 4,
    };
    res.params = {
        ...res.params,
        ...fixedData,
    };
    if (!res.headers) {
        res.headers = {};
    }
    const query = localStorage.getItem('query');
    res.headers['Content-Type'] = 'application/json';
    res.headers['appId'] = 'wx9213698c6999c980';
    res.headers['query'] = query ? query : '';
    if (store.state.token) {
        res.headers['token'] = store.state.token;
    } else if (localStorage.getItem('token')) {
        res.headers['token'] = localStorage.getItem('token');
    }
    //res.headers['token'] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQ3NzA3LCJleHAiOm51bGwsImlhdCI6MTcwMjg4MjQyMH0=.ZIMiaRPCMPbGNNopurOiuPIEzXZPxY5C3h6Gjk5P4VQ=';
    return res;
}
util.axios.interceptors.request.use(requestConfig);
util.axios.interceptors.response.use(res.resolve, res.reject);

util.logAxios = axios.create({
    baseURL: util.logBaseUrl,
    headers:{},
    withCredentials: false
});
util.logAxios.interceptors.request.use(requestConfig);
util.logAxios.interceptors.response.use(res.resolve, res.reject);


util.userAgent = navigator.userAgent

util.checkMiniProgram = function () {
    if (window.__wxjs_environment === 'miniprogram' || util.userAgent.indexOf('miniProgram') > -1) {
        return true
    } else {
        return false
    }
}
util.checkAndroid = function () {
    return util.userAgent.indexOf('Android') > -1 || util.userAgent.indexOf('Adr') > -1
}
util.checkIOS = function () {
    return /iPhone|iPad|iPod/i.test(util.userAgent)
}

util.checkWechat = function () {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') !== -1;
}

util.checkMobileWechat = function () {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') !== -1 && ua.indexOf('mobile') !== -1;
}

util.showLoading = function (text) {
    text = text ? text : 'loading'
    let loading = weui.loading(text)
    store.commit('setLoading', loading)
}

util.hideLoading = function (text) {
    if (store.state.loading) {
        store.state.loading.hide(function() {
            console.log('`loading` has been hidden');
        });
    }
    store.commit('setLoading', null)
}

util.isReachBottom = function () {
    // 页面内容高度
    const documentHeight = document.documentElement.scrollHeight;
    // 滚动条距离顶部的高度
    const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;
    // 窗口可视高度
    const clientHeight = document.documentElement.clientHeight;

    return scrollHeight + clientHeight >= documentHeight;
}

util.objectToQueryString = function (obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}

util.wechatConfig = function () {
    util.axios.get('api/js/ticket?url=' + encodeURIComponent(location.href.split('#')[0]), {}).then(function (res) {
        if (res.code == 100) {
            wx.config({
                debug: false,
                appId: res.data.info.appId,
                timestamp: res.data.info.timestamp,
                nonceStr: String(res.data.info.nonceStr),
                signature: res.data.info.signature,
                jsApiList: [
                    'hideAllNonBaseMenuItem',
                ],
            });
            wx.ready(function(){
                wx.hideAllNonBaseMenuItem();
            });
            wx.error(function(res){
                console.log('wx.error', res)
            });
        }
    }).catch(function (error) {
        console.log('jsTicket获取失败', error)
    });
}

util.pageStopover = function (path, time) {
    if (time == 0) {
        return;
    }
    let newData = {
        time: time,
        path: path,
        scene: 0,
    };
    let that = this;
    setTimeout(function () {
        util.logAxios.post('api/applet/v1/user/page/stopover', newData).then(function (res) {
            console.log('上报停留时间成功', res)
        }).catch(function (error) {
            console.log('上报停留时间失败', error)
        });
    }, 1000);
}

util.accessLog = function (path, data) {
    let newData = {
        path: path,
        scene: 0,
        ...data,
    };
    console.log('accessLog', newData)
    let that = this;
    setTimeout(function () {
        util.logAxios.post('api/applet/v1/user/access/log', newData).then(function (res) {
            console.log('上报访问日志成功', res)
        }).catch(function (error) {
            console.log('上报访问日志失败', error)
        });
    }, 1000);
}

util.decrypt = function (ciphertext) {
    const key = 'xaDkKxek9CRA0JwVGsGit1M3nvfUZDAQ';
    const iv = 'i2Fuzs7i8sI5alS3';
    const replacedCipher = ciphertext.replace(/-/g, '+').replace(/\*/g, '/');
    const bytes = CryptoJS.AES.decrypt(replacedCipher, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    let decryptedStr = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedStr);
}

export default util