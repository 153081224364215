<template>
	<div class="container">
		<div class="top">
			<div class="my">
				<div class="my-left">
					<img :src="avatar" />
				</div>
				<div class="my-right">
					<div class="my-info">
						<div>积分: <span>{{balance}}</span></div>
						<div>连续签到7天可获得积分奖励</div>
					</div>
					<div class="my-button" v-if="!todayIsSigned" @click="toSignin">立即签到</div>
				</div>
			</div>
			<div class="signin">
				<div class="signin-top">
					<div class="signin-top-title">已连续签到 <span>{{dayNum}}</span> 天</div>
				</div>
				<div class="signin-bottom">
					<template v-for="(item, index) in list">
						<div class="signin-bottom-date" :key="index" :class="{'checked':item.isSigned, 'seven':index==7}" :data-index="index" :data-status="item.isSigned ? true : false">
							<template v-if="index < 7">
								<div class="date-title">第{{index}}天</div>
								<img v-if="item.isSigned" src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/welfare/finished.png" />
								<img v-else src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/welfare/unfinished.png" />
								<div class="date-amount">{{item.amount}}米豆</div>
							</template>
							<template v-if="index == 7">
								<div class="seven-left">
									<div class="date-title">第{{index}}天</div>
									<div class="date-amount">神秘惊喜大礼</div>
								</div>
								<div class="seven-right">
									<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/welfare/seven.png" />
								</div>
							</template>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div class="bottom">
		</div>
		<Recharge :popupShow="rechargeShow" :playletId="0" :episode="0" :hideFunc="rechargeHideFunc" :paySuccessFunc="paySuccess" :payFailFunc="payFail"></Recharge>
	</div>
</template>

<script>
import Recharge from "../components/Recharge.vue"

export default {
	name: 'Welfare',
	components: {
		Recharge,
	},
	data() {
		return {
			topHeight: 90,
			list: [],
			dayNum: 0,
			todayIsSigned: true,
			balance: 0,
			rechargeShow: false,
			animationData: '',
			enterTime: 0,
			path: '',
			avatar: '',
		}
	},
	activated() {
		this.$util.setTitle('福利');
		this.path = '/welfare' + window.location.search;
		this.enterTime = new Date().getTime();
	},
	deactivated() {
		const leaveTime = new Date().getTime();
		const stayTime = leaveTime - this.enterTime;
		this.$util.pageStopover(this.path, Math.floor(stayTime / 1000));
	},
	beforeDestroy() {
		const leaveTime = new Date().getTime();
		const stayTime = leaveTime - this.enterTime;
		this.$util.pageStopover(this.path, Math.floor(stayTime / 1000));
	},
	mounted() {
		this.loadData();
		this.$util.setTitle('福利');
		this.$util.wechatConfig();
		this.path = '/welfare' + window.location.search;
		this.enterTime = new Date().getTime();
		this.$util.accessLog(this.path, {});
	},
	methods: {
		loadData() {
			let that = this;
			that.$util.showLoading()
			that.$util.axios.get('api/applet/v1/user/info', {}).then(function (res) {
				that.balance = res.data.userInfo.balance;
				that.avatar = res.data.userInfo.avatar;
			}).catch(function (error) {
				console.log('user/info获取失败', error)
			});
			that.$util.axios.get('api/applet/v1/user/signed/list', {}).then(function (res) {
				that.dayNum = res.data.day;
				that.list = res.data.list;
				that.todayIsSigned = res.data.todayIsSigned;
			}).catch(function (error) {
				console.log('signed/list获取失败', error)
			});
		},
		toSignin() {
			let that = this;
			that.$util.showLoading()
			that.$util.axios.post('api/applet/v1/user/signed', {}).then(function (res) {
				that.loadData();
			}).catch(function (error) {
				console.log('signed获取失败', error)
			});
		},
		rechargeShowFunc() {
			this.rechargeShow = true;
		},
		rechargeHideFunc() {
			this.rechargeShow = false;
		},
		paySuccess() {
			console.log('支付成功')
			this.rechargeHideFunc();
			this.loadData();
		},
		payFail() {
			console.log('支付失败')
		},
	},
}
</script>

<style scoped>
.container {
	/*background: linear-gradient(180deg, #FFB670 0%, #F28C2A 100%);*/
	min-height: 100vh;
	background-color: #F7F7F7;
}
.top {
	/*height: 100vh;*/
	background-image: url("https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/welfare/bg.png");
	background-repeat: no-repeat;
	background-size: 100% auto;
	padding-top: 1.8rem;
}
.my {
	width: 6.66rem;
	margin: 0 auto;
	display: flex;
}
.my .my-left {
	width: 0.92rem;
	height: 0.92rem;
	border-radius: 50%;
	overflow: hidden;
}
.my .my-left img {
	width: 100%;
	height: 100%;
	display: block;
}
.my .my-right {
	width: 5.52rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	font-size: 0.24rem;
	color: #FFFFFF;
	margin-left: 0.22rem;
}
.my-info view:nth-child(1) {
	margin-bottom: 0.08rem;
	font-weight: 500;
	font-size: 0.30rem;
}
.my-info div span {
	color: #FFFD00;
}
.my-button {
	background: rgba(255, 255, 255, 0.6);
	border-radius: 0.36rem;
	text-align: center;
	font-weight: 500;
	font-size: 0.28rem;
	color: #08A669;
	padding: 0.14rem 0.28rem;
	box-sizing: border-box;
}


.signin {
	width: 7rem;
	height: 4.60rem;
	background: #FFFFFF;
	box-shadow: 0 0.12rem 0.12rem 0.10rem rgba(224,224,224,0.16);
	border-radius: 0.16rem;
	box-sizing: border-box;
	margin: 0.40rem auto 0;
	overflow: hidden;
}
.signin-top {
	height: 0.56rem;
	padding: 0.28rem 0 0 0.32rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.signin-top-title {
	font-weight: 500;
	font-size: 0.28rem;
	color: #282828;
}
.signin-top-title span {
	font-size: 0.32rem;
	font-weight: 600;
	color: #F22D33;
}
.signin-bottom {
	padding: 0 0.28rem;
	display: flex;
	flex-wrap: wrap;
}
.signin-bottom-date {
	width: 1.36rem;
	height: 1.56rem;
	margin-right: 0.33rem;
	background: rgba(229,231,237, 0.6);
	font-weight: 500;
	font-size: 0.24rem;
	color: #282828;
	padding: 0.16rem 0;
	box-sizing: border-box;
	text-align: center;
	margin-top: 0.24rem;
	border-radius: 0.16rem;
}
.signin-bottom .signin-bottom-date:nth-child(4n) {
	margin-right: 0;
}
.date-title {
	height: 0.34rem;
	line-height: 0.34rem;
}
.date-amount {
	font-weight: 500;
	font-size: 0.20rem;
	color: #999999;
}
.signin-bottom-date img {
	width: 0.62rem;
	height: 0.60rem;
	display: block;
	margin: 0.04rem auto;
}
.checked {
	background: linear-gradient( 124deg, #56E9B1 0%, #56E8B0 100%);
	color: #FFFFFF!important;
}
.checked .date-amount {
	color: #FFFFFF!important;
}
.seven {
	width: 3.06rem;
	margin-right: 0;
	display: flex;
	padding: 0;
	justify-content: space-between;
}
.seven img {
	width: 0.90rem;
	height: 0.92rem;
}
.seven .date-amount {
	margin-top: 0.04rem;
}
.seven-left {
	text-align: left;
	margin: 0.14rem 0 0 0.38rem;
}
.seven-right {
	margin-right: 0.26rem;
}
.bottom {
	height: 0.20rem;
}
</style>