<template>
	<div class="top">
		<div class="user-data">
			<div class="logo">
				<img :src="userInfo.avatar">
			</div>
			<div class="info">
				<div class="info-open">
					<span v-if="userInfo.isVip">{{userInfo.expireTime}}</span>
					<span v-else @click="rechargeMember">开通会员</span>
				</div>
				<div class="info-name">Hello，{{userInfo.nickname}}</div>
				<div class="info-id" @click="copy">
					<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/my/id.png" />
					<div>{{userInfo.identifier}}</div>
				</div>
			</div>

			<div class="vip">
				<div class="vip-title">我的米豆</div>
				<div class="vip-content">
					<div class="amount">{{userInfo.balance}}</div>
					<div class="recharge" @click="rechargeShowFunc">去充值</div>
				</div>
				<div class="vip-button">
					<div class="button" @click="toAward">联系客服</div>
					<div class="button" @click="toRecord">消费记录</div>
					<div class="button" @click="toWelfare">免费赚豆</div>
				</div>
			</div>

			<div class="collect">
				<div class="collect-name">我的追剧</div>
				<div class="collect-more" @click="toList" data-type="2"></div>
				<div class="teleplay-list" v-if="collect.length > 0">
					<div class="teleplay" v-for="item in collect" :key="item.id" @click="toDetail" :data-id="item.id">
						<div class="img-container"><img :src="item.cover" alt=""></div>
						<div class="title">{{item.name}}</div>
					</div>
				</div>
				<div class="empty" v-else>
					<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogram/theater/index/empty4.png" />
				</div>
			</div>

			<div class="collect">
				<div class="collect-name">观看历史</div>
				<div class="collect-more" @click="toList" data-type="1"></div>
				<div class="teleplay-list" v-if="viewing.length > 0">
					<div class="teleplay" v-for="item in viewing" :key="item.id" @click="toDetail" :data-id="item.id">
						<div class="img-container"><img :src="item.cover" alt=""></div>
						<div class="title">{{item.name}}</div>
					</div>
				</div>
				<div class="empty" v-else>
					<img src="https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogram/theater/index/empty4.png" />
				</div>
			</div>
		</div>


		<Recharge :popupShow="rechargeShow" :playletId="0" :episode="0" :hideFunc="rechargeHideFunc" :paySuccessFunc="paySuccess" :payFailFunc="payFail"></Recharge>
	</div>
</template>

<script>
import Recharge from "../components/Recharge.vue"

export default {
	name: 'My',
	components: {
		Recharge,
	},
	props: {
		changeTabFunc: Function,
	},
	data() {
		return {
			userInfo: {
				id: 0,
				identifier: '',
				avatar: '',
				nickname: '',
				isVip: 0,
				balance: 0,
				expireTime: '',
			},
			clickCount: 0,
			rechargeShow: false,
			recharges: [],
			rechargeCheckedIndex: '',
			enterTime: 0,
			path: '',
			collect: [],
			viewing: [],
		}
	},
	activated() {
		this.$util.setTitle('我的');
		this.path = '/my' + window.location.search;
		this.enterTime = new Date().getTime();
	},
	deactivated() {
		const leaveTime = new Date().getTime();
		const stayTime = leaveTime - this.enterTime;
		this.$util.pageStopover(this.path, Math.floor(stayTime / 1000));
	},
	beforeDestroy() {
		const leaveTime = new Date().getTime();
		const stayTime = leaveTime - this.enterTime;
		this.$util.pageStopover(this.path, Math.floor(stayTime / 1000));
	},
	mounted() {
		this.loadData();
		this.$util.setTitle('我的');
		this.$util.wechatConfig();
		this.path = '/my' + window.location.search;
		this.enterTime = new Date().getTime();
		this.$util.accessLog(this.path);
	},
	methods: {
		loadData() {
			let that = this;
			that.$util.showLoading()
			that.$util.axios.get('api/applet/v1/user/info', {}).then(function (res) {
				console.log('user获取成功', res)
				that.userInfo = res.data.userInfo;
			}).catch(function (error) {
				console.log('user获取失败', error)
			});
			that.$util.axios.post('api/applet/v1/playlet/recharge/template', {
				playletId: 0,
			}).then(function (res) {
				console.log('充值模版', res)
				let rechargeCheckedIndex = '';
				if (res.data.list.length > 0) {
					res.data.list.forEach(function (item, index) {
						if (item.type == 2) {
							rechargeCheckedIndex = index;
						}
					});
				}
				that.recharges = res.data.list;
				that.rechargeCheckedIndex = rechargeCheckedIndex;
			}).catch(function (error) {
				console.log('充值模版获取失败', error)
			});
			that.$util.axios.get('api/applet/v1/user/viewing/record', {}).then(function (res) {
				if (res.code == 100) {
					that.viewing = res.data.list;
				}
			}).catch(function (error) {
				console.log('viewing获取失败', error)
			});
			that.$util.axios.get('api/applet/v1/user/collect/list', {}).then(function (res) {
				if (res.code == 100) {
					that.collect = res.data.list;
				}
			}).catch(function (error) {
				console.log('collect获取失败', error)
			});
		},
		copy() {
			let that = this;
			const textToCopy = that.userInfo.id;
			that.$copyText(textToCopy).then(() => {
				console.log('文本已复制');
				that.$toast('复制成功')
			}).catch(() => {
				console.error('复制失败');
				that.$toast('复制失败')
			});
		},
		toRecord() {
			this.$router.push({
				path: '/my-record'
			})
		},
		toWelfare() {
			this.changeTabFunc(2);
		},
		toList(e) {
			this.$router.push({
				path: '/list',
				query: {
					t: e.currentTarget.dataset.type
				}
			})
		},
		toAward() {
			this.$router.push({
				path: '/wechat'
			})
		},
		toDetail(e) {
			console.log(e)
			console.log(e.currentTarget.dataset.id)
			this.$router.push({
				path: '/detail',
				query: {
					plet_id: e.currentTarget.dataset.id
				}
			})
		},
		elementClick() {
			this.clickCount = this.clickCount + 1;
			if (this.clickCount >= 6) {
				this.triggerCustomEvent();
				this.clickCount = 0;
			}
		},
		triggerCustomEvent() {
			localStorage.removeItem('token');
			localStorage.removeItem('query');
			this.$toast('清除成功')
		},
		rechargeShowFunc() {
			this.rechargeShow = true;
		},
		rechargeHideFunc() {
			this.rechargeShow = false;
		},
		paySuccess() {
			console.log('支付成功')
			this.rechargeHideFunc();
			this.loadData();
		},
		payFail() {
			console.log('支付失败')
		},
		rechargeMember() {
			let that = this;
			that.$util.showLoading();
			let index = that.rechargeCheckedIndex;
			if (!index) {
				that.$util.hideLoading();
				return;
			}
			that.$util.axios.post('api/applet/v1/playlet/create/order', {
				playletId: 0,
				episode: 0,
				rechargeId: that.recharges[index].id,
			}).then(function (res) {
				console.log('创建订单', res.data)

				if (res.code == 100) {
					const onBridgeReady = function () {
						WeixinJSBridge.invoke('getBrandWCPayRequest', res.data,
								function(res) {
									console.log('支付结果', res)
									if (res.err_msg == "get_brand_wcpay_request:ok") {
										that.$toast('支付成功')
										that.paySuccess();
									} else {
										that.$toast('支付失败')
										that.payFail();
									}
								});
					}
					if (typeof WeixinJSBridge == "undefined") {
						if (document.addEventListener) {
							document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
						} else if (document.attachEvent) {
							document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
							document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
						}
					} else {
						onBridgeReady();
					}
				}
			}).catch(function (error) {
				that.$toast('订单创建失败')
				console.log('订单创建失败', error)
			});
		},
	}
}
</script>

<style scoped>
.top {
	padding-top: 2.72rem;
	background-image: url("https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/my/bg.png");
	background-repeat: no-repeat;
	background-size: 100% auto;
	height: 100vh;
	background-color: #ffffff;
	margin-bottom: 1.2rem;
}
.user-data {
	background-color: #ffffff;
	border-top-left-radius: 0.20rem;
	border-top-right-radius: 0.20rem;
}
.user-data .logo {
	width: 1.60rem;
	height: 1.60rem;
	background: #FFFFFF;
	border: 0.04rem solid #FFFFFF;
	border-radius: 50%;
	overflow: hidden;
	position: absolute;
	top: 1.90rem;
	margin-left: 0.44rem;
}
.user-data .logo img {
	width: 1.60rem;
	height: 1.60rem;
	border-radius: 50%;
}
.user-data .info {
	margin-left: 0.28rem;
	width: 5.14rem;
	overflow: hidden;
}
.info-open {
	width: 2.12rem;
	height: 0.88rem;
	line-height: 0.60rem;
	right: 0;
	position: absolute;
	background-image: url("https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/my/open-bg.png");
	background-repeat: no-repeat;
	background-size: 100% auto;
	font-weight: 500;
	font-size: 0.26rem;
	color: #262626;
	text-align: right;
	box-sizing: border-box;
	padding-right: 0.30rem;
}
.user-data .info-name {
	height: 0.50rem;
	font-weight: bold;
	font-size: 0.36rem;
	color: #262626;
	line-height: 0.50rem;
	margin-top: 0.96rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-left: 0.42rem;
}
.user-data .info-id {
	font-weight: 400;
	font-size: 0.24rem;
	color: #262626;
	margin-top: 0.12rem;
	margin-left: 0.42rem;
	display: flex;
	align-items: center;
	margin-bottom: 0.14rem;
}
.user-data .info-id img {
	width: 0.20rem;
	height: 0.20rem;
	display: block;
	margin-right: 0.04rem;
}
.vip {
	background-image: url("https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/my/vip-bg.png");
	background-repeat: no-repeat;
	background-size: 100% auto;
	width: 6.90rem;
	height: 2.64rem;
	margin: 0 auto 0.20rem;
	box-sizing: border-box;
	padding: 0.4rem 0.36rem 0.30rem;
	background-color: #ffffff;
}
.vip {
	position: relative;
}
.vip-title {
	font-weight: 400;
	font-size: 0.28rem;
	color: #333333;
	text-align: left;
}
.vip-content {
	height: 0.80rem;
	font-weight: bold;
	font-size: 0.68rem;
	color: #333333;
	line-height: 0.80rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.vip-content .recharge {
	width: 1.46rem;
	height: 0.68rem;
	line-height: 0.68rem;
	background-color: #FDD81A;
	font-weight: 500;
	font-size: 0.28rem;
	color: #262626;
	text-align: center;
	border-radius: 0.40rem;
}
.vip-button {
	margin-top: 0.28rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.vip-button .button {
	font-weight: 400;
	font-size: 0.26rem;
	color: #999999;
}
.vip-button .button + .button::before {
	content: "|";
	margin: 0 0.68rem;
	color: #999999;
	height: 0.18rem;
}


.collect {
	width: 7.20rem;
	background: #FFFFFF;
	border-radius: 0.16rem;
	margin: 0.38rem 0 0 0.30rem;
	box-sizing: border-box;
	position: relative;
}
.collect .collect-name {
	font-weight: 600;
	font-size: 0.34rem;
	color: #333333;
	text-align: left;
}
.collect .collect-more {
	width: 0.36rem;
	height: 0.36rem;
	background-image: url("https://yidu-image.oss-cn-beijing.aliyuncs.com/miniprogramV2/my/more.png");
	background-size: 0.36rem;
	position: absolute;
	top: 0.06rem;
	right: 0.26rem;
}
.collect .teleplay-list {
	margin-top: 0.18rem;
	display: flex;
	justify-content: flex-start;
	white-space: nowrap;
	height: 3.24rem;
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.collect .teleplay-list::-webkit-scrollbar {
	display: none !important;
	width: 0 !important;
	height: 0 !important;
	-webkit-appearance: none !important;
	background: transparent !important;
	color:transparent !important;
}
.collect .teleplay-list .teleplay {
	width: 2.02rem;
	margin-right: 0.18rem;
	display: inline-block;
}
.collect .teleplay-list .teleplay .img-container {
	width: 2.02rem;
	height: 2.62rem;
	border-radius: 0.10rem;
	overflow: hidden;
	position: relative;
}
.img-container img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.collect .teleplay-list .teleplay .title {
	height: 0.36rem;
	width: 2.02rem;
	overflow: hidden;
	margin-top: 0.12rem;
	font-size: 0.26rem;
	color: #333333;
	font-weight: 400;
}
</style>