import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'
import util from '../libs/util'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { keepAlive: true }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail2.vue'),
    meta: {
      fromOtherPage: false,
      keepAlive: false,
    },
    beforeEnter: (to, from, next) => {
      if (from.name != null) {
        to.meta.fromOtherPage = true;
      }
      console.log('from', from)
      next();
    }
  },
  {
    path: '/my-record',
    name: 'my-record',
    component: () => import('../views/MyRecord.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/List.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/wechat',
    name: 'wechat',
    component: () => import('../views/Wechat.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue'),
    meta: { keepAlive: true }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/Error.vue'),
    meta: { keepAlive: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('router.beforeEach', to, from)
  if (to.path != '/error' &&!util.checkMobileWechat()) {
    next('/error');
    return;
  }
  if (to.path != '/error' && !store.state.token && !localStorage.getItem('token')) {
    console.log('需要登录')
    console.log('to.query', to.query)
    if (!to.query.code) {
      const encodedURL = encodeURIComponent(window.location.href);
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9213698c6999c980&redirect_uri="+encodedURL+"&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
      return;
    } else {
      util.showLoading('登录中');
      util.axios.post('api/applet/v1/login', {
        code: to.query.code,
      }).then(function (res) {
        console.log('调用接口登录', res)
        if (res.code == 100) {
          localStorage.setItem('token', res.data.token);
          store.commit('setToken', res.data.token)
          next();
        } else {

        }
      }).catch(function (error) {
        console.log('调用接口登录失败', error)
      });
    }
  } else {
    next();
  }
})

export default router
